@tailwind components;
@tailwind utilities;
html,
body {
  overflow-x: hidden;
  overflow-y: auto;
}
body {
  margin: 0;
  font-size: 16px;

  /* font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif; */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

* {
  font-family: Poppins, sans-serif;
  transition: background 0.3s, fill 0.3s, stork 0.3s, color 0.3s, box-shadow 0.3s;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace;
}

.main-content {
  min-height: calc(100vh - 80px - 64px) !important;
  padding-bottom: 72px;
}

.main-content-inner {
  min-height: calc(100vh - 80px - 64px - 72px) !important;
}
