.loading {
  @apply !m-0;

  :global {
    .aelfd-modal-content {
      @apply !w-[240px] mx-auto !p-[16px];
    }
  }
}

.loadingWithClose {
  :global {
    .aelfd-modal-content {
      @apply !w-[295px];
    }
  }
}
